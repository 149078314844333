import { useForm } from 'react-hook-form'

import {
  Navigation,
  TokenAgain,
  Input,
  If
} from '@itau-loans-www/shopping/src/components'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import useSmsCodeVerification from './hooks'
import './sms-code-verification.scss'

const SmsCodeVerification = ({ pageContext }) => {
  const initialValues = {
    'token-digit-0': '',
    'token-digit-1': '',
    'token-digit-2': '',
    'token-digit-3': ''
  }

  const formProps = useForm({
    mode: 'onBlur',
    defaultValues: initialValues
  })

  const {
    handleSubmit,
    setValue,
    trigger,
    resetField,
    formState: { isValid }
  } = formProps

  const {
    onLoadData,
    onSubmit,
    tokenValidation,
    getTokenFromSmsIos,
    sendSms,
    isIOS,
    submitLoading
  } = useSmsCodeVerification({ setValue, trigger, resetField })

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={submitLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 15px 0"
              widthDesktop="85%"
              height="64px"
              heightDesktop="30px"
            />
            <Shimmer margin="0 0 50px 0" widthDesktop="60%" height="24px" />
            <Shimmer margin="0 0 45px 0" widthDesktop="40%" height="48px" />
            <Shimmer widthDesktop="65%" height="36px" />
            <Shimmer
              margin="190px 0 0 0"
              marginDesktop="140px 0 0 0"
              widthDesktop="50%"
              height="50px"
            />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">
              Insira o código que enviamos para o seu celular
            </h1>
            <p className="phone" data-cy="cy-users-phone">
              {onLoadData.phone ? (
                `Enviamos para o número ${onLoadData.phone}`
              ) : (
                <Shimmer height="16px" />
              )}
            </p>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <Input.Token
                label="Código"
                size={4}
                validators={tokenValidation}
                formProps={formProps}
                shouldResetOn={null}
                // Type tel está preenchendo apenas 1 digito
                // no autocomlete do iphone no chrome
                // por isso mantemos o type number
                // para o plataformas mobile iOS
                type={isIOS ? 'number' : 'tel'}
                onlyNumbers
                onInput={(e) => {
                  getTokenFromSmsIos(e.target.value)
                }}
              />

              <input
                type="text"
                aria-hidden="true"
                inputMode="numeric"
                name="one-time-code"
                autoComplete="one-time-code"
                onChange={(e) => {
                  const value = e?.target?.value
                  getTokenFromSmsIos(value)
                }}
                style={{
                  position: 'absolute',
                  zIndex: '-9999',
                  left: '-100%'
                }}
              />

              <TokenAgain sendSms={sendSms} />
              <Navigation
                submit
                dataCy="cy-btn-token-next"
                nextStep
                isNextButtonDisabled={!isValid}
                nextButtonId="sms-code-verification-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default SmsCodeVerification
