import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'
import { storage } from 'utils'

const postSendSms = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'send_sms_code',
    method: 'post'
  })

  return data
}

const postSmsToken = async (requestBody) => {
  const tokensAtstore = storage.session.getItem('token')

  const { data } = await defaultLeadsApiCall({
    endpoint: 'authentication',
    method: 'post',
    body: requestBody
  })

  const authentication = data.auth

  const token = {
    ...tokensAtstore,
    accessToken: authentication['token'],
    startDate: authentication['server_time'],
    validTime: authentication['valid_time']
  }

  storage.session.setItem('token', JSON.stringify(token))

  return data
}

const getSmsToken = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'authentication',
    method: 'get'
  })

  return data
}

const getStepInfo = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'step_info',
    method: 'get'
  })

  return data
}

export default { postSendSms, postSmsToken, getSmsToken, getStepInfo }
